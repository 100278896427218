const renderVideo = (button) => {
  const videoUrl = button.getAttribute('data-video') + '?autoplay=1';
  const iframe = document.createElement('iframe');

  iframe.setAttribute('src', videoUrl);
  iframe.setAttribute('class', 'section-2-card_video');
  iframe.setAttribute('frameborder', 0);
  iframe.setAttribute(
    'allow',
    'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
  );
  iframe.setAttribute('allowfullscreen', true);

  const container = button.closest('.section-2-card');
  const image = container.querySelector('picture');

  if (image) {
    container.removeChild(image);
  }

  container.removeChild(button);

  container.appendChild(iframe);
};

document.addEventListener('DOMContentLoaded', function () {
  const videoButtons = document.querySelectorAll('.play-video');

  videoButtons.forEach((button) => {
    button.addEventListener('click', () => renderVideo(button));
  });
});
